import { BUSINESS_LOGIN, PROFILE_DETAIL } from "./Type";

const initialState = {
  isBusinessLogin: false,
  BuinessDetails: [],
  token: "",
};
const profileState = {
  ProfileDetails: [],
};


export const businessLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_LOGIN:
      return {
        ...state,
        BuinessDetails: action.payload,
        isBusinessLogin: true,
        token: action.payload.data,
      };

    default:
      return state;
  }
};

export const ProfileDetails = (state = profileState, action) => {
  switch (action.type) {
    case PROFILE_DETAIL:
      return {
        ...state,
        ProfileDetails: action.payload,
      };
    default:
      return state;
  }
};
