
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PagesIndex from "../container/pages/business/PagesIndex";
import { useEffect } from "react";

const UsePrivateRoutes = () => {
  const { pathname } = useLocation();
  const navigate=PagesIndex.useNavigate();
 const fetchData = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Business.Get_StatusBusiness
      );
      if (res?.data.status === 200) {
        if (
          res?.data?.data?.isActive === false 
          || res?.data?.data?.isDeleted === true 

        ) {
          localStorage.clear();
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

useEffect(()=>{
  fetchData();
},[pathname])
  
  const adminToken = localStorage.getItem("Token");

  return adminToken ? <Outlet /> : <Navigate to="/" replace />;
};

export default UsePrivateRoutes;