import PagesIndex from "../../container/pages/business/PagesIndex.js";
import {  businessLogin, profileData } from "./APIService.js";
import { BUSINESS_LOGIN, PROFILE_DETAIL } from "./Type";


export const BusinessLogged = (data, navigate,setLoading) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await businessLogin(data);
    setLoading(false);
    if (res?.status === 200) {
      PagesIndex.toast.success(res.message);
      localStorage.setItem("Token", res.data.token);
        navigate("/business/dashboard");
        setLoading(false)
    }
    dispatch({
      type: BUSINESS_LOGIN,
      payload: res,
    });
  } catch (res) {
    setLoading(false);
    PagesIndex.toast.error(res?.response?.data?.message);
  }
};

export const ProfileDetails=async(dispatch)=>{
  try {
    const res=await profileData()
    dispatch({
      type: PROFILE_DETAIL,
      payload: res.data,
    });
  } catch (error) {
    
  }
}
