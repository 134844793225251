import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import { createBrowserHistory } from "history";
import { FailureMessage } from "../container/pages/business/thankYou/Thankyou";
import { ToastContainer } from "react-toastify";
import UsePrivateRoutes from "./UsePrivateRoutes";
import UgoLoader, { UgoSkeletonLoader } from "../component/common/UgoLoader";

const Home = lazy(() => import("../container/pages/business/home/Home"));
const Dashboard = lazy(() =>
  import("../container/pages/business/dashboard/Dashboard")
);
const Transaction = lazy(() =>
  import("../container/pages/business/transaction/Transaction")
);
const BusinessContact = lazy(() =>
  import("../container/pages/business/businessContact/BusinessContact")
);
const CreateAdvert = lazy(() =>
  import("../container/pages/business/home/createAdvert/CreateAdvert")
);
const ChangePassword = lazy(() =>
  import("../container/auth/business/changepassword/ChangePassword")
);
const Login = lazy(() => import("../container/auth/business/login/Login"));
const OtpVerification = lazy(() =>
  import("../container/auth/business/otpVerification/OtpVerification")
);
const SignUp = lazy(() => import("../container/auth/business/signup/SignUp"));
const ForgotPassword = lazy(() =>
  import("../container/auth/business/forgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../container/auth/business/resetPassword/ResetPassword")
);
const BusinessProfile = lazy(() =>
  import("../container/pages/business/businessProfile/BusinessProfile")
);
const TermsCondition = lazy(() =>
  import("../container/pages/business/termsCondition/TermsCondition")
);
const PrivacyPolicy = lazy(() =>
  import("../container/pages/business/privacyPolicy/PrivacyPolicy")
);
const Thankyou = lazy(() =>
  import("../container/pages/business/thankYou/Thankyou")
);
const UgoPageNotFound = lazy(() =>
  import("../component/common/UgoPageNotFound")
);

const history = createBrowserHistory();

const Routes = () => {
  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<UgoLoader color="#233862" />}>
        <Routess>
          <Route path="*" element={<UgoPageNotFound />} />
          <Route path="/" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="otpverification" element={<OtpVerification />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="/loader" element={<UgoLoader />} />
          <Route path="/skeletonloader" element={<UgoSkeletonLoader />} />
          <Route element={<UsePrivateRoutes />}>
            <Route path="/business" element={<Dashboard />}>
              <Route path="dashboard" element={<Home />} />
              <Route path="changepassword" element={<ChangePassword />} />
              <Route path="detail" element={<BusinessProfile />} />
              <Route path="thankyou" element={<Thankyou />} />
              <Route path="transaction" element={<Transaction />} />
              <Route path="contact" element={<BusinessContact />} />
              <Route path="createadvert/:status" element={<CreateAdvert />} />
              <Route path="terms-condition" element={<TermsCondition />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="sucessUrl" element={<Thankyou />} />
              <Route path="cancelUrl" element={<FailureMessage />} />
            </Route>
          </Route>
        </Routess>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Routes;
