import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";

const persistStore = Store();

ReactDOM.render(
  <Provider store={persistStore.store}>
    <PersistGate persistor={persistStore.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
