import { combineReducers } from 'redux';
import { ProfileDetails, businessLoginReducer } from './business/Reducer';

const RootReducer = combineReducers({
    businessLoginReducer: businessLoginReducer,
    ProfileDetails:ProfileDetails,

});

export default RootReducer;
