import './App.css';
import Routes from './routes/Routes'
import './assets/style/global.css';
import './assets/style/global.responsive.css'
function App() {
  return (
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
