import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ClipLoader } from "react-spinners";


const UgoLoader = ({ color, loading, ariaLabel, dataTestId }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <SyncLoader
        color={color}
        loading={loading}
        size={14}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        style={{ transform: "none" }}
      />
    </div>
  );
};

export default UgoLoader;

export const UgoSkeletonLoader = () => {
  return (
    <div>
      <Skeleton height={300} style={{ marginBottom: '10px',marginTop:'20px' }} />
      <Skeleton count={4} height={20} style={{marginTop:'10px'}} />
    </div>
  );
}

export const UgoButtonLoader = () => {
  return (
    <ClipLoader size={20} color="#ffff"/> 
  );
}


export const UgoSkeletonProfileLoader= () => {
  return (
    <div>
      <Skeleton height={80} style={{ marginBottom: '10px',marginTop:'10px' }} />
    </div>
  );
}


