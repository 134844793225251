const Api = {
  Business: {
    Business_Register: "business/register",
    Business_Login: "business/login",
    Business_ForgotPassword: "business/forgotPassword",
    Business_VerifyOtp: "business/verifyOtp",
    Business_ResetPassword: "business/resetPassword",
    Business_ChangePassword: "business/changePassword",
    Create_Advertisment: "business/advertisment/create",
    Update_Profile: "business/updateProfile",
    View_Profile: "business/viewProfile",
    Update_TermsCondition: "business/editCompanyCMS?type=terms",
    Update_PrivacyPolicy: "business/editCompanyCMS?type=privacy",
    Get_TermsCondition: "business/getCompanyCMS?type=terms",
    Get_PrivacyPolicy: "business/getCompanyCMS?type=privacy",
    Get_Transactions: "business/transactions",
    Profile_Deactivate: "business/businessDelete",
    Advert_Payment: "business/createPayment",
    Transactions_Status: "business/paymentStatus",
   Get_ContactUs:"business/getContactUs",
   Update_ContactUs:"business/addEditContactUs",
   Get_Orders:"business/analytics/clicks",
   Get_Vouchers:"business/analytics/vouchers",
   Get_StatusBusiness:"business/getSingleBusiness"
  },

  Common: {
    Get_ListAllCategory: "common/list/category",
    Get_ListSubCategory: "common/list/subCategoryWithCategory",
    Get_AllAdvertisment: "common/advertisment",
    Google_Place:"common/place-suggestions?parameters=",
    Get_CoupanPrice:"common/coupan-price"
  },
};
export { Api };
