import { Api } from "../../config/Api";
import { DataService } from "../../config/DataService";

export const businessLogin = async (data) => {
  const res = await DataService.post(Api.Business.Business_Login, data);
  return res?.data;
};

export const profileData = async () => {
  const res = await DataService.get(Api.Business.View_Profile);
  return res?.data;
};