import React, { useEffect, useState } from "react";
import Index from "../../Index";
import "./defaultLayout.css";
import PagesIndex from "../../../container/pages/business/PagesIndex";
import { UgoSkeletonProfileLoader } from "../../common/UgoLoader";
import { ProfileDetails } from "../../../redux/business/Action";
import { useSelector } from "react-redux";
import blankImage from "../../../assets/images/png/blank-imge.2.png"
function Sidebar() {
  const location = Index.useLocation();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = PagesIndex.useDispatch();
  const handleClose = () => {
    document.body.classList["remove"]("sidebar-active");
  };
  const handleClickAccount = () => {
    setOpen(!open);
  };

  const profileData = useSelector(
    (state) => state.ProfileDetails.ProfileDetails
  );

  useEffect(() => {
    dispatch(ProfileDetails);
  }, []);

  return (
    <>
      <Index.Box className="sidebar-inner-main">
        <Index.Box className="sidebar-body">
          <Index.Box className="sidebar-top res-sidebat-top">
            <Index.Box>
              <Index.Button className="res-close-btn">
                {" "}
                <img
                  src={Index.Svg.close}
                  onClick={handleClose}
                  className="res-close-img"
                />
              </Index.Button>
            </Index.Box>
            {loading ? (
              <UgoSkeletonProfileLoader />
            ) : (
              <>
                <Index.Box className="d-flex align-items-center">
                  <Index.Box className="profile-image-main">
                    <img
                      src={profileData?.businessImage?`${PagesIndex.imageURL}${profileData?.businessImage}`: `${blankImage}`}
                      alt="profile image"
                      className="profile"
                    />
                  </Index.Box>
                  <Index.Box className="welcome-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="welcome-text"
                    >
                      Welcome
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="bussiness-name-text"
                    >
                      {profileData?.fullName}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </>
            )}
          </Index.Box>
          <Index.Box className="sidebar-bottom-main">
            <Index.Box className="sidebar-main">
              <Index.List className="sidebar-ul">
                <Index.ListItem>
                  <Index.Link
                    to="/business/dashboard"
                    className={`admin-sidebar-link ${
                      location.pathname === "/business/dashboard"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Index.Box className="sidebar-li-inner">
                      <Index.Box className="sidebarul-image">
                        {" "}
                        <img src={Index.Svg.sideadvertise} />
                      </Index.Box>

                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text"
                      >
                        Advertise
                      </Index.Typography>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>

                <Index.ListItem>
                  <Index.Link
                    to="/business/transaction"
                    className={`admin-sidebar-link ${
                      location.pathname === "/business/transaction"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Index.Box className="sidebar-li-inner">
                      <Index.Box className="sidebarul-image">
                        {" "}
                        <img src={Index.Svg.tanimg} />
                      </Index.Box>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text"
                      >
                        {" "}
                        Transactions
                      </Index.Typography>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>

                <Index.ListItem>
                  <Index.Link
                    to="/business/detail"
                    className={`admin-sidebar-link ${
                      location.pathname === "/business/detail" ? "active" : ""
                    }`}
                  >
                    <Index.Box className="sidebar-li-inner">
                      <Index.Box className="sidebarul-image">
                        {" "}
                        <img
                          src={
                            active
                              ? Index.Svg.activeSideProfile
                              : Index.Svg.sideprofile
                          }
                        />
                      </Index.Box>

                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text"
                      >
                        Profile
                      </Index.Typography>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>

                <Index.ListItem>
                  <Index.Link
                    to="/business/changepassword"
                    className={`admin-sidebar-link ${
                      location.pathname === "/business/changepassword"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Index.Box className="sidebar-li-inner">
                      <Index.Box className="sidebarul-image">
                        {" "}
                        <img src={Index.Svg.sidesetting} />
                      </Index.Box>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text"
                      >
                        Settings
                      </Index.Typography>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>

                <Index.ListItem>
                  <Index.Link
                    to="/business/contact"
                    className={`admin-sidebar-link ${
                      location.pathname === "/business/contact" ? "active" : ""
                    }`}
                  >
                    <Index.Box className="sidebar-li-inner">
                      <Index.Box className="sidebarul-image">
                        {" "}
                        <img src={Index.Svg.call} />
                      </Index.Box>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text"
                      >
                        Contact us{" "}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>

                <Index.ListItem
                  className="admin-sidebar-listitem admin-submenu-listitem-main"
                  onClick={handleClickAccount}
                >
                  <Index.Link className="cms-container">
                    <Index.Box className="sidebar-setting-box">
                      <Index.LockIcon className="sidebar-setting-icon" />
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar-text cms-text"
                      >
                        CMS
                      </Index.Typography>

                      {open ? (
                        <Index.ExpandLess className="expandless-icon" />
                      ) : (
                        <Index.ExpandMore className="expandmore-icon" />
                      )}
                    </Index.Box>
                    <Index.Box className="submenu-main">
                      <Index.Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className="submenu-collapse"
                      >
                        <Index.List
                          component="div"
                          disablePadding
                          // className="admin-sidebar-submenulist"
                        >
                          <Index.ListItem className="admin-sidebar-listitem">
                            <Index.Link
                              to="/business/terms-condition"
                              className={`sidebar-text cms-dropdown ${
                                location.pathname ===
                                "/business/terms-condition"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Terms & Conditions
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem className="admin-sidebar-listitem privacy-policy">
                            <Index.Link
                              to="/business/privacy-policy"
                              className={`sidebar-text cms-dropdown  ${
                                location.pathname === "/business/privacy-policy"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Privacy Policy
                            </Index.Link>
                          </Index.ListItem>
                        </Index.List>
                      </Index.Collapse>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
